
//modified version of http://snipplr.com/view/3561/addclass-removeclass-hasclass

/**
 * Helper method that returns a regular expression for the supplied classname.
 *
 * @param {string} className - The name of the class as a string.
 * @returns
 */
const classRegEx = (className) => {
    return new RegExp(`(\\s|^)${className}(\\s|$)`);
};

/**
 * Helper method for checking whether the <body> has a certain class.
 *
 * @param {string} className - The name of the class as a string.
 */
const hasClass = (className) => {
    return document.body.className.match(classRegEx(className));
};

/**
 * Helper method for adding a class to the <body> if it doesn't have that class yet.
 *
 * @param {string} className - The name of the class as a string.
 */
const addClass = (className) => {
    if (className != null && className.trim() !== '' && !hasClass(className)) {
        document.body.className = `${document.body.className} ${className}`.trim();
    }
};

/**
 * Helper method for removing a class from the <body>.
 *
 * @param {string} className - The name of the class as a string.
 */
const removeClass = (className) => {

    if (className != null && className.trim() !== '' && hasClass(className)) {
        const reg = classRegEx(className);

        document.body.className = document.body.className.replace(reg,' ');
    }
};

/**
 * Helper method for removing all classes from the <body>.
 *
 * @returns
 */
const clearAll = () => {
    document.body.className = '';
};

/**
 * Helper method for removing all classes from the <body>.
 *
 * @param {string,array} exceptClasses - Single classname as string, or array of classnames (as strings) that must not be removed.
 * @returns
 */
const clearAllExcept = (exceptClasses) => {

    if(exceptClasses.constructor !== Array) {
        exceptClasses = [ exceptClasses ];
    }

    exceptClasses = exceptClasses.filter((className) => {
        return hasClass(className);
    });

    clearAll();

    exceptClasses.map((className) => {
        return addClass(className);
    });
};

export default {
    hasClass,
    addClass,
    removeClass,
    clearAll,
    clearAllExcept
};