import React from 'react';
import ReactEcharts from 'echarts-for-react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DataTable from '../../universe/molecules/dataTable';

//import colors from '../../data/colors.json';

class StockPile extends React.Component {

    componentDidMount() {
        const { load, match } = this.props;

        if (typeof load === 'function') {
            load(match.params.stockpilenr);
        }

    }

    shouldComponentUpdate(nextProps) {
        const { data, match } = this.props;
        return (JSON.stringify(data[ match.params.stockpilenr ]) !== JSON.stringify(nextProps.data[ match.params.stockpilenr ]));
    }

    render() {

        const { data, match } = this.props;

        if (data == null || data[ match.params.stockpilenr ] == null || data[ match.params.stockpilenr ].length === 0) {
            return (<div>Loading...</div>);
        }

        const dataBlock = data[ match.params.stockpilenr ].data;
        const metaBlock = data[ match.params.stockpilenr ].meta;

        const date = data[ match.params.stockpilenr ].data.map(d => ( new moment(d[ 'arrivedAtEMO' ]).format('YYYY-MM-DD')));
        const data2 = data[ match.params.stockpilenr ].data.map(d => (d[ 'currentStock' ]));

        const option = {
            tooltip: {
                trigger: 'axis',
                position: function (pt) {
                    return [ pt[ 0 ], '10%' ];
                },
                formatter: (params) => {
                    const dataIndex = params[ 0 ].dataIndex;

                    const dateString = new moment(dataBlock[ dataIndex ][ 'arrivedAtEMO' ]).format('YYYY-MM-DD');
                    const actualMutation = dataBlock[ dataIndex ][ 'mutation' ];

                    const who = (Math.sign(actualMutation) === 1) ? `${dataBlock[ dataIndex ][ 'vessel' ]} delivered ` : `${dataBlock[ dataIndex ][ 'craft' ]} received`;

                    return `<div>Stock at this moment: ${dataBlock[ dataIndex ][ 'currentStock' ].toLocaleString()}</div><div>Event: On ${dateString} ${who} ${Math.abs(actualMutation).toLocaleString()} for ${dataBlock[ dataIndex ][ 'receiver' ]}</div>`;

                }
            },
            title: {
                left: 'center',
                xtext: 'Historical Stock',
            },
            toolbox: {
                feature: {
                    show: false,
                    dataZoom: {
                        title: {
                            zoom: 'Zoom',
                            back: 'Zoom Reset'
                        },
                        yAxisIndex: 'none'
                    },
                    restore: {
                        title: 'Restore'
                    },
                    saveAsImage: {
                        title: 'Save',
                        lang: [ 'Right Click to Save Image' ]
                    }
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: date
            },
            yAxis: {
                type: 'value',
                boundaryGap: [ 0, '100%' ]
            },
            dataZoom: [ {
                type: 'inside',
                start: 0,
                end: 100
            }, {
                start: 0,
                end: 10,
                handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                handleSize: '80%',
                handleStyle: {
                    color: '#fff',
                    shadowBlur: 3,
                    shadowColor: 'rgba(0, 0, 0, 0.6)',
                    shadowOffsetX: 2,
                    shadowOffsetY: 2
                }
            } ],
            series: [
                {
                    name:'Current Stock',
                    type:'line',
                    smooth:true,
                    symbol: 'none',
                    sampling: 'average',
                    itemStyle: {
                        color: '#00a261'
                    },
                    areaStyle: {
                        color: '#00a261'
                    },
                    data: data2
                }
            ]
        };

        return (<div>
            <Link to={'/'}>Back to dashboard</Link>

            <h2>Stock pile information</h2>
            <DataTable
                data={[ data[ match.params.stockpilenr ] ]}
                meta={ data[ match.params.stockpilenr ].objectMeta }
                columnNames={{
                    'stockPileNr': 'Stock Pile Nr',
                    'product': 'Product',
                    'currentStock': 'Current Stock'
                }}
                columnsToFilter={[ 'meta','data','objectMeta','lastModified','exceptionMessages','validationErrors' ]}
            />

            <h2>Stock history</h2>
            <ReactEcharts
                option={option}
                style={{
                    height: '500px', width: '100%'
                }}
                className="react_for_echarts"

            />
            <div>
                <h2>Journeys</h2>

                <DataTable
                    data={dataBlock}
                    meta={metaBlock}

                    columnsToFilter={[ 'craft', 'currentStock', 't1DocNr', 'journeyId' ]}
                    columnNames={{
                        'vessel': 'Vessel',
                        'arrivedAtEMO': 'Arrived at EMO',
                        'mutation': 'B/L Weight',
                        'completedDischarge': 'Completed Discharge',
                        't1DocNr': 'T1DocNr',
                        'receiver': 'Receiver'
                    }}
                    columnLink={[ 'vessel', 'receiver' ]}

                    createLink={(field, row, content) => {

                        switch(field) {
                        case 'receiver':
                            return <Link to={`/receiver/${row[ 'receiver' ]}/${match.params.stockpilenr}`}>{content}</Link>;
                        default:
                            return <Link to={`${match.url}/${row[ 'journeyId' ]}`}>{content}</Link>;

                        }
                    }}

                    dataFilter={r => (r.mutation >= 0)}

                />
            </div>

        </div>);

    }
}

export default StockPile;