import React from 'react';
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render () {
        return (
            <div>
                <Navbar className="navbar fixed-top navbar-expand-lg bg-white navbar-light shadow-sm" color="light" light expand="lg" >
                    <div className="container">
                        <NavbarBrand href={process.env.PUBLIC_URL}>
                            <img src="https://www.vertom.nl/wp-content/themes/vertom/assets/images/logo-vertom.png" alt="Vertom" />
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggle} className="navbar-toggler rounded-0 border-0 bg-primary" />
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className="ml-auto navbar-light" navbar>
                                <NavItem className="nav-item">
                                    <NavLink href="https://www.vertom.nl">Home</NavLink>
                                </NavItem>

                                { (this.props.location != null && this.props.location.pathname.toLowerCase().indexOf( 'access-denied' ) !== -1) ? null :
                                    <NavItem className={
                                        (this.props.location.pathname === '/' ? ' active current-menu-item' : '')

                                    }>
                                        <Link className={
                                            'nav-link' + (this.props.location.pathname === '/' ? ' active' : '')

                                        } to={'/'}>Stock Monitoring Dashboard</Link>
                                    </NavItem>
                                }
                            </Nav>
                        </Collapse>
                    </div>
                </Navbar>
            </div>
        );
    }
}

export default Header;