import React from 'react';
import moment from 'moment';

const Status = ({ lastModified, errors, validationErrors, }) => (
    <div className="row">
        { lastModified == null ? null :
            <div className="col-12">
                <div className="badge badge-default float-right">Last modified: {new moment(lastModified).format('YYYY-MM-DD HH:mm:ss')}</div>
            </div>
        }

        {errors == null ? null : errors.map(e =>
            <div key={e} className="col-12">
                <div className="badge badge-danger float-right">{e}</div>
            </div>
        )}

        {validationErrors == null ? null : validationErrors.map(e =>
            <div key={e} className="col-12">
                <div className="badge badge-warning float-right">{e}</div>
            </div>
        )}
    </div>

);

export default Status;