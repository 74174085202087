import React from 'react';
import moment from 'moment';
import { Table } from 'react-bootstrap';

export const DataTable = ({ data, meta, columnsToFilter = [], columnNames = {}, columnLink = [], createLink = (field, row, content) => (content), dataFilter = () => (true), formatter, mdHide = [] }) => {

    if (data == null || meta == null) {
        return (<div>Loading...</div>);
    }

    const internalFormatter = (index, field, fieldType, row, includeLink, className) => {

        if (typeof row[ field ] === 'object' || Array.isArray(row[ field ]) ){
            return (<td key={`rc_${field}_${index}`} className={className}> </td>);
        }
        switch (fieldType) {
        case 'DateTime':
            return (<td key={`ra_${field}_${index}`} className={className}>{moment(row[ field ]).format('L')}</td>);

        case 'Double':
            return (<td key={`rb_${field}_${index}`} style={{
                textAlign: 'right',
                color: Number(row[ field ]) < 0 ? 'red' : 'inherit'
            }} className={className}>{Number(row[ field ]).toLocaleString()}</td>);
        default:
            return (<td key={`rc_${field}_${index}`} className={className}>{includeLink ? createLink(field, row, row[ field ]) : row[ field ] }</td>);
        }
    };

    const valueFormatter = (typeof formatter === 'function' ? formatter : null) || internalFormatter;

    return (
        <Table striped bordered hover size="sm">
            <thead>
                <tr>

                    {meta.map(m => (m.propertyName)).filter(h => columnsToFilter.find(c => c === h) == null).map(h =>
                        (<th key={`h_${h}`} className={mdHide.find(f => f === h) == null ? null : 'd-none d-lg-block'}>{ columnNames[ h ] || h}</th>)

                    )}

                </tr>
            </thead>
            <tbody>
                {data.filter(dataFilter).length === 0
                    ? <tr><td colSpan={meta.map(m => (m.propertyName)).filter(h => columnsToFilter.find(c => c === h) == null).length}>No data available</td></tr>
                    : data.filter(dataFilter).map((row, index) =>
                        (<tr key={`r_${index}` }>
                            {Object
                                .keys(data[ 0 ])
                                .filter(h => meta.find(m => m[ 'propertyName' ] === h) != null)
                                .filter(h => columnsToFilter.find(c => c === h) == null)
                                .map((h) => (valueFormatter( index, h, (meta.find(m => m[ 'propertyName' ] === h) || {
                                    propertyType: 'object'
                                }).propertyType, row, columnLink.find(c => c === h), mdHide.find(f => f === h) == null ? null : 'd-none d-lg-block' ) ))
                            }
                        </tr>))
                }
            </tbody>
        </Table>
    );

};

export default DataTable;