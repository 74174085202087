import * as Actions from './actions';
import { stateHelper, DATA_STATE_KEY } from './_references';
import { DataKey } from './constants';

const mapState = (state = {}) => ({
    data: stateHelper(state, [ DATA_STATE_KEY, DataKey ], {})
});

const mapDispatch = (dispatch) => ({
    load: (...params) => dispatch(Actions.loadData(...params))
});

export {
    mapState,
    mapDispatch
};