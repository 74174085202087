import { AuthenticationContext } from 'react-adal';

const _adal = {};

export const authContext = (adalConfig) => {
    _adal.config = adalConfig;

    if (_adal.context == null) {
        _adal.context = new AuthenticationContext(adalConfig);
    }

    return _adal.context;
};

export const getToken = () => {
    return authContext(_adal.config).getCachedToken(_adal.config.clientId);
};