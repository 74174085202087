import Axios from 'axios';

import { logger } from './_references';
import DefaultConfig from './defaultConfiguration.json';

const loadAdalConfiguration = () => (
    new Promise(resolve => {
        const url = process.env.PUBLIC_URL + '/adalConfig.json';
        const epoch = new Date() / 1;
        const urlNoCache = url + '?no-cache=' + epoch;

        Axios.get(urlNoCache).then(response => (
            new Promise(resolve => {
                resolve(response.data);
            })
        )).then(config => {
            resolve(Object.assign({}, {
                cacheLocation: 'sessionStorage'
            }, config));
        });
    })
);

const loadConfiguration = () => {
    return new Promise((resolve) => {
        const url = process.env.PUBLIC_URL + '/config.json';
        const epoch = new Date() / 1;
        const urlNoCache = url + '?no-cache=' + epoch;

        logger.info('Loading configuration');
        resolve(Axios.get(urlNoCache));
    })
        .then(handleConfiguration);
};

const handleConfiguration = (response) => {
    const config = response.data;

    return new Promise((resolve, reject) => {
        logger.info('Configuration loaded');

        if (typeof config === 'string') {
            reject('Door een configuratie fout is de applicatie momenteel niet beschikbaar.');
        }
        else {
            // applying defaults filled with actual config
            Axios.defaults.AppConfig = Object.assign({}, DefaultConfig, config);

            resolve({
                config: Axios.defaults.AppConfig
            });
        }
    });
};

export default loadConfiguration;

export { loadAdalConfiguration };