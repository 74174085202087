import React from 'react';
import { Route } from 'react-router-dom';

import Header from './modules/header';
import Container from './modules/container';
import Homepage from './modules/homepage';

function App() {
    return (
        <div className="App">
            <Route component={Header} />
            <Route>
                <Container>
                    <Homepage />
                </Container>
            </Route>
        </div>
    );
}

export default App;
