import React from 'react';
import { Link } from 'react-router-dom';

import { stateHelper } from '../../util/stateManager';

//import colors from '../../data/colors.json';

import DataTable from '../../universe/molecules/dataTable';

class Journey extends React.Component {

    componentDidMount() {
        const { load, match } = this.props;

        if (typeof load === 'function') {
            load(match.params.journeyid);
        }

    }

    shouldComponentUpdate(nextProps) {
        const { data, match } = this.props;
        return (JSON.stringify(data[ match.params.journeyid ]) !== JSON.stringify(nextProps.data[ match.params.journeyid ]));
    }

    render() {

        const { data, match } = this.props;

        const dataBlock = stateHelper(data, [ match.params.journeyid, 'transactions' ]);
        const metaBlock = stateHelper(data, [ match.params.journeyid, 'transactionMeta' ]);
        const vesselDataBlock = stateHelper(data, [ match.params.journeyid, 'vessel' ]);
        const vesselMetaBlock = stateHelper(data, [ match.params.journeyid, 'vesselMeta' ]);
        const stockLocationDataBlock = stateHelper(data, [ match.params.journeyid, 'stockLocation' ]);
        const stockLocationMetaBlock = stateHelper(data, [ match.params.journeyid, 'stockLocationMeta' ]);

        return (<div>
            <Link to={`/stockpile/${match.params.stockpilenr}`}>Back to stock pile</Link>

            <h2>Vessel Information</h2>

            <DataTable
                data={[ vesselDataBlock ]}
                meta={vesselMetaBlock}
                columnNames={{
                    'name': 'Vessel',
                    'imoNumber': 'IMO Number',
                    'portOfDischarge': 'Port of Discharge',
                    'agency': 'Agency'
                }}
                columnsToFilter={[ 'mmsi' ]}
                columnLink={[ 'imoNumber' ]}
            />

            <h2>Stock Location per Vessel</h2>

            <DataTable
                data={[ stockLocationDataBlock ]}
                meta={stockLocationMetaBlock}
                columnNames={{
                    'owner': 'Owner',
                    'product': 'Product',
                    'outturn': 'Outturn',
                    'terminal': 'Terminal',
                    'port': 'Port',
                    'stockPileNr': 'Stock Pile Nr'
                }}
            />

            <h2>Stock Overview</h2>
            <DataTable
                data={dataBlock}
                meta={metaBlock}
                columnNames={{
                    'date': 'Date',
                    'receivingCraftCombined': 'Receiving Craft',
                    'amountReleased': 'Amount Released',
                    't1DocNr': 'T1 Doc. Nr.',
                    'extraStorageDocNr': 'Extra Storage Doc. Nr.',
                    'currentStock': 'Current Stock'
                }}
                columnsToFilter={[ 'craftName', 'craftType' ]}

            /></div>);
    }
}

export default Journey;