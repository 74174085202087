// TODO: Redo!

/* Stock Pile Dashboard */
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import ReactEcharts from 'echarts-for-react';

import DataTable from '../../universe/molecules/dataTable';
import colors from '../../data/colors.json';

class DataProvider {

    constructor(data = [], meta = [], colors) {
        this.data = data;
        this.meta = meta;
        this.colors = colors;

        this.types = data
            .map(d => (d[ 'Quality' ]))
            .filter((value, index, self) => (self.indexOf(value) === index))
            .map((q, i) => ({
                Quality: q,
                Index: i
            }));

    }

    getDataFor (columnName, columnIndex) {
        return this.data.map(row => ({
            value: row[ columnName ],
            itemStyle: {
                color: this.getColor(row[ 'Quality' ], columnIndex).backgroundColor,
                borderColor: this.getColor(row[ 'Quality' ], columnIndex).borderColor,
                borderWidth: 3,
                borderType: 'solid'
            }
        }));
    }

    getColor (quality, columnIndex) {
        return this.colors[ (this.types
            .find(t => t.Quality === quality) || {
            Index: 0
        }).Index * 4 + columnIndex ];
    }

    getOptions () {
        return {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: this.data.map(row => (`${row[ 'Stock Pile Nr' ]}`)),
                    axisTick: {
                        alignWithLabel: true
                    },
                    axisLabel: {
                        showMinLabel: this.data.length,
                        rotate: 45
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value'
                }
            ],
            series: [ /*'B/L Weight', 'Outturn', 'Loaded Ex Stock',*/ 'Current Stock' ].map((column, index, all) => ({
                name: column,
                type: 'bar',
                barWidth: `${(100 - (all.length * 2) / all)}%`,
                data: this.getDataFor(column, index)

            }))

        };
    }

}

class Dashboard extends Component {

    componentDidMount() {
        const { load } = this.props;

        if (typeof load === 'function') {
            load();
        }

    }

    renderComponent() {
        const { data, meta } = this.props;

        const dataProvider = new DataProvider(data, meta, colors);

        return (
            <div>
                <div className="row">
                    {
                        dataProvider.data.map((item, index) => (

                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={index}>
                                <h2>{item[ 'Stock Pile Nr' ]}</h2>
                                <h3 style={{
                                    fontSize: '1em'
                                }}>{item[ 'Quality' ]}</h3>
                                <div style={{
                                    paddingBottom: '1em'
                                }}>
                                    {[ /* 'B/L Weight', 'Outturn', 'Loaded Ex Stock', */ 'Current Stock' ].map((columnName, idx) => (

                                        <div key={idx} style={{
                                            fontSize: '3em', textAlign: 'center'
                                        }}>
                                            <span style={Object.assign({}, {
                                                display: 'inline-block', minWidth: '100%', borderWidth: '2px', borderStyle: 'solid'
                                            }, dataProvider.getColor(item[ 'Quality' ], idx))}>{item[ columnName ].toLocaleString()}</span>
                                        </div>

                                    ))}
                                </div>

                                <div className="d-block d-md-none" style={{
                                    marginTop: '1.5em', marginBottom: '2.3em'
                                }}>
                                    <LinkContainer to={`/stockpile/${item[ 'stockPileId' ]}`}>
                                        <Button className="btn-block">Details</Button>
                                    </LinkContainer>
                                </div>
                            </div>

                        ))

                    }
                </div>

                <div className="d-none d-md-block">
                    <h2>Current Stock per Stock Pile</h2>
                    <ReactEcharts
                        option={dataProvider.getOptions()}
                        onEvents={{
                            'click':({ ...rest }) => {

                                this.props.history.push(`/stockpile/${dataProvider.data[ rest.dataIndex ][ 'stockPileId' ]}`);
                            }
                        }}
                        style={{
                            height: '500px', width: '100%'
                        }}
                        className="react_for_echarts"

                    />

                    <DataTable
                        data={dataProvider.data}
                        meta={dataProvider.meta}
                        columnsToFilter={[ 'stockPileId', 'journeys' ]}
                        columnLink={[ 'Stock Pile Nr' ]}
                        mdHide={[ 'Outturn' ]}
                        createLink={(_field, row, content) => (<Link to={`/stockpile/${row[ 'stockPileId' ]}`}>{content}</Link> )}
                    />
                </div>

            </div>
        );
    }

    renderLoading() {
        return (<div>Loading.....</div>);
    }

    render() {
        const { data, meta } = this.props;

        if (data == null || meta == null) {
            return this.renderLoading();
        }
        return this.renderComponent();

    }
}

export default Dashboard;
