import Axios from 'axios';
import { getToken, logger, axiosLogger, LoadingActions, Actions, ActionTypes } from './_references';

const configureApi = ({ store, config, adalConfig, ...rest }) => {

    return new Promise((resolve) => {

        logger.info('Configuring API');

        Axios.defaults.baseURL = config.baseUrl || '/';
        Axios.defaults.withCredentials = true;

        Axios.interceptors.request.use(
            (request) => {
                store.dispatch(LoadingActions.addCounterAction()); //set application state to loading when an api call is dispatched

                request.headers = Object.assign({}, request.headers, {
                    Authorization: `Bearer ${getToken(adalConfig)}`
                });

                return request;
            }
        );

        // hooks to handle the response
        Axios.interceptors.response.use(
            /* istanbul ignore next */
            (response) => {

                store.dispatch(LoadingActions.removeCounterAction()); //set application state to not loading when an api call dispatch is resolved

                try {
                    axiosLogger.info(`received from ${response.request.responseURL}`, response);
                }
                catch (err) {
                    axiosLogger.error('Could not parse response.data', response, err);
                }

                // set success to true when status is OK
                response.success = (response.status === 200 || response.status === 204);

                return response;
            },
            /* istanbul ignore next */
            (error) => {
                store.dispatch(LoadingActions.removeCounterAction()); //set application state to not loading when an api call dispatch is resolved

                store.dispatch(Actions.ApiCallFailed(error));

                axiosLogger.error('Request failed', error);

                if ((error.config || {}).suppressErrors) {
                    return Promise.reject(error);
                }
                else {
                    // Always return a default action for redux
                    return {
                        type: ActionTypes.API_CALL_FAILED,
                        response: error.response
                    };
                }
            });

        // Axios has been configured by now so dispatch an action to pre load data
        store.dispatch(Actions.preLoad(config));

        resolve({
            store, config, adalConfig, ...rest
        });
    });
};

export default configureApi;