import { all, call, put, takeEvery } from 'redux-saga/effects';
import { DataApi, GlobalActionTypes, DataLoaderActionTypes } from './_references';

import * as Actions from './actions';
import * as Constants from './constants';

const dataPreloaderSaga = function* () {
    yield all([
        takeEvery( GlobalActionTypes.PRE_LOAD, preloadData ),
        takeEvery( DataLoaderActionTypes.LOAD_DATA, loadData )
    ]);
};

const preloadData = function* ( action ) {
    const { data } = action.config;

    const keys = Object.keys(data);

    for (let i = 0; i < keys.length; i += 1) {
        const key = keys[ i ];

        const result = yield call (DataApi.getData, data[ key ]);

        yield put (Actions.dataLoaded(key, result.data));
    }
};

const loadData = function* ( action ) {
    const { key, url } = action;

    const result = yield call (DataApi.getData, url);

    if (result.status === 200) {
        yield put (Actions.dataLoaded(key, result.data));

        yield put (Actions.dataLoaded( Constants.LAST_MODIFIED_KEY, result.data.lastModified));
        yield put (Actions.dataLoaded( Constants.EXCEPTION_MESSAGES_KEY, result.data.exceptionMessages || []));
        yield put (Actions.dataLoaded( Constants.VALIDATION_MESSAGES_KEY, result.data.validationErrors || []));
    }
};

export default dataPreloaderSaga;