import { combineReducers } from 'redux';
import { logger } from './_references';
// import {
//     LoginActionTypes,
// } from './_references.js';

import * as ReducerReferences from './configureRootReducer.reducers';

const configureRootReducer = () => {

    logger.info('Configuring root reducer');

    const Reducers = Object.assign({}, ReducerReferences);

    if (Object.keys(Reducers).length === 0){
        logger.warn('Adding a dummy reducer');
        // add a dummy reducer to avoid an error
        Reducers._ = (state = {}) => (state);
        Reducers._.STATE_KEY = 'Dummy Reducer';
    }

    var reducers = combineReducers(
        Object.assign(
            {},
            // loop over all reducers that we import and export for each reducer an object consisting of a STATE_KEY and the actual reducer.
            ...Object.keys(Reducers)
                .map((reducerKey) => {
                    if (!Reducers[ reducerKey ].STATE_KEY) {
                        throw new Error ( `Reducer ${reducerKey} does not have a STATE_KEY property. Please add it before exporting the reducer function.` );
                    }

                    return {
                        [ Reducers[ reducerKey ].STATE_KEY ] : Reducers[ reducerKey ]
                    };
                })
        ));

    return reducers;
};

export default configureRootReducer;