import React from 'react';
import * as Constants from './constants';

const NoAccess = ({ reason }) => (
    <div>
        <div>Access has been denied.</div>
        { reason !== Constants.Reasons.NOT_PART_OF_ORGANISATION ? null : <div>You are currently logged in with an account that is not associated with the Vertom organisation.</div> }
        { reason !== Constants.Reasons.NOT_ALLOWED ? null : <div>You do not have permissions to access this application.</div> }
        <div>Please contact your contact at Vertom.</div>
    </div>
);

export default NoAccess;