import React from 'react';
import ReactDOM from 'react-dom';
import Axios from 'axios';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';

import loadConfiguration from './loadConfiguration';
import configureStore from './configureStore';
import configureApi from './configureApi';
import dispatchConfig from './dispatchConfig';
import handleLoginError from './handleLoginError';

import { logger } from './_references';

/**
 * This method will
 * * Configure the application
 * * Configure the store
 * * Configure the api
 * * Start the application
 */
const bootstrap = (rootId, RootNode, loginWasTriedButFailed) => {

    // enable logging on dev only
    Axios.defaults.AppConfig = {
        enableLogging : process.env.NODE_ENV !== 'production'
    };

    loadConfiguration()
        // push additional properties to the context
        .then(context => (new Promise(resolve => {
            resolve(Object.assign({
                loggedInWithWrongUser: loginWasTriedButFailed
            }, context));
        })))
        .then(configureStore)
        .then(dispatchConfig)
        .then(configureApi)
        .then(handleLoginError)
        .then(({ store, sagaContext }) => {
            ReactDOM.render(
                <Router>
                    <SagaContextBuilder sagaContext={sagaContext}>
                        <Provider store={store}>
                            <RootNode />
                        </Provider>
                    </SagaContextBuilder>
                </Router>,
                document.getElementById(rootId)
            );
        })
        .catch(err => {

            logger.error(err);

            if (typeof err === 'string') {
                ReactDOM.render(
                    <div>{err}</div>,
                    document.getElementById('root')
                );
            }
            else {
                ReactDOM.render(
                    <div>De applicatie is momenteel niet beschikbaar</div>,
                    document.getElementById('root')
                );
            }

        });

};

const SagaContextBuilder = withRouter(
    class ContextBuilder extends React.Component {

        constructor(props) {
            super(props);
            // push the history object to the saga context
            this.props.sagaContext[ 'history' ] = this.props.history;
        }

        render() {
            return Array.isArray(this.props.children) ? this.props.children : [ this.props.children ];
        }

    }
);

export default bootstrap;