import { all } from 'redux-saga/effects';

import * as Sagas from './configureSaga.sagas';

/**
 * Adds all sagas to the root saga.
 */
export default function* rootSaga(context) {
    yield all(
        Object.keys(Sagas)
            .map((saga) => {
                return Sagas[ saga ](context);
            })
    );
}