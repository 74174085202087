import { STATE_KEY, Fields, Reasons } from './constants';

import { LOGGED_IN_WRONG_USER } from '../../configuration/actionTypes';

import { setStateValue } from './_references';

const reducer = (state = {}, action) => {

    switch (action.type) {
    case LOGGED_IN_WRONG_USER:
    {
        return setStateValue(Fields.REASON, Reasons.NOT_PART_OF_ORGANISATION, state);
    }
    default:
        break;
    }

    return state;
};

reducer.STATE_KEY = STATE_KEY;

export default reducer;