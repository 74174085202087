import React from 'react';
import ReactEcharts from 'echarts-for-react';
import DataTable from '../../universe/molecules/dataTable';
import { Link } from 'react-router-dom';

class Receiver extends React.Component {

    componentDidMount() {
        const { load, match } = this.props;

        if (typeof load === 'function') {
            load(match.params.receiverid);
        }

    }

    render() {

        const { data , match } = this.props;

        if (data == null || data[ match.params.receiverid ] == null) {
            return (<div>Loading...</div>);
        }

        const option = {
            color: [ '#00a261' ],
            tooltip : {
                trigger: 'axis',
                axisPointer : {
                    type : 'shadow'
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis : [
                {
                    type : 'category',
                    data : data[ match.params.receiverid ].data.map(d => (`${d.year}-${d.month}`) ),
                    axisTick: {
                        alignWithLabel: true
                    }
                }
            ],
            yAxis : [
                {
                    type : 'value'
                }
            ],
            series : [
                {
                    name:'Amount released',
                    type:'bar',
                    barWidth: '60%',
                    data: data[ match.params.receiverid ].data.map(d => d.amountReleased)
                }
            ]
        };

        return (
            <div>

                {match.params.stockpilenr == null ? <Link to={'/'}>Back to dashboard</Link> :

                    <Link to={`/stockpile/${match.params.stockpilenr}`}>Back to stock pile</Link>
                }

                <h2>Receiver Information</h2>

                <DataTable
                    data={[ data[ match.params.receiverid ] ]}
                    meta={[ {
                        'propertyName': 'name', 'propertyType': 'string'
                    } ]}
                    columnNames={{
                        'name': 'Name'
                    }}
                />

                <h2>Received per month</h2>

                <ReactEcharts
                    option={option}
                    style={{
                        height: '500px', width: '100%'
                    }}
                    className="react_for_echarts"

                />

                <DataTable

                    data={data[ match.params.receiverid ].data}
                    meta={data[ match.params.receiverid ].meta}

                    columnsToFilter={[ 'transactions' ]}

                    columnNames={{
                        'year': 'Year',
                        'month': 'Month',
                        'amountReleased': 'Amount Released'
                    }}
                />

            </div>
        );

    }

}

export default Receiver;