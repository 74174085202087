import React from 'react';

const Bs4Size = () => process.env.NODE_ENV === 'production' ? (null) : (
    <span style={{
        position : 'fixed',
        right: '1px',
        top: '1px',
        zIndex: 9999
    }}>
        <span className="badge badge-warning d-inline-block d-sm-none">XS</span>
        <span className="badge badge-warning d-none d-sm-inline-block d-md-none">SM</span>
        <span className="badge badge-warning d-none d-md-inline-block d-lg-none">MD</span>
        <span className="badge badge-warning d-none d-lg-inline-block d-xl-none">LG</span>
        <span className="badge badge-warning d-none d-xl-inline-block">XL</span>
    </span>
);

export default Bs4Size;