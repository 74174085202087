import * as Actions from './actions';
import { stateHelper, DATA_STATE_KEY } from './_references';
import { DataKey } from './constants';

export const mapState = (state = {}) => ({
    data: stateHelper(state, [ DATA_STATE_KEY, DataKey, 'data' ]),
    meta: stateHelper(state, [ DATA_STATE_KEY, DataKey, 'meta' ]),
    lastModified: stateHelper(state, [ DATA_STATE_KEY, DataKey, 'lastModified' ]),
    errors: stateHelper(state, [ DATA_STATE_KEY, DataKey, 'exceptionMessages' ], []),
    validationErrors: stateHelper(state, [ DATA_STATE_KEY, DataKey, 'validationErrors' ], []),
});

export const mapDispatch = (dispatch) => ({
    load: (...params) => dispatch(Actions.loadData(...params))
});
