import './css/adjustments.css';

import App from './App';
import bootstrap from './configuration/bootstrap';
import { runWithAdal } from 'react-adal';
import { authContext } from './adal';
import { loadAdalConfiguration } from './configuration/loadConfiguration';

loadAdalConfiguration()
    .then(adalConfig => {
        const context = authContext(adalConfig);
        // https://github.com/salvoravida/react-adal/issues/33
        const loginError = context.getLoginError();
        const loginWasTriedButFailed = loginError !== undefined && loginError !== null && loginError !== '';
        const redirectIfNoUser = !loginWasTriedButFailed;

        runWithAdal(
            context,
            () => {
                bootstrap('root', App, loginWasTriedButFailed);
            },
            !redirectIfNoUser
        );
    });
