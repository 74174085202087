import React from 'react';
import Bs4Size from '../../universe/molecules/bs4size';
const Container = ({ children }) => (
    <section className="content mb-0 hero">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div style={{
                        paddingTop: '2em'
                    }}>

                        {children}
                    </div>

                </div>
            </div>
        </div>
        <Bs4Size/>
    </section>
);

export default Container;