import { ActionTypes } from './_references';

const dispatchConfig = ({ store, config, ...rest }) => {
    return new Promise((resolve) => {

        store.dispatch({
            type: ActionTypes.ACTION_CONFIGURATION_CHANGED, config
        });

        resolve({
            store, config, ...rest
        });

    });
};

export default dispatchConfig;