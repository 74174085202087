import React from 'react';

import { Route } from 'react-router-dom';

import Status from '../status';
import Dashboard from '../dashboard';
import StockPile from '../stockPile';
import Journey from '../journey';
import Receiver from '../receiver';
import NoAccess from '../noAccess';

const Homepage = () => (
    <div>
        <Status />
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/stockpile/:stockpilenr" component={StockPile} />
        <Route path="/stockpile/:stockpilenr/:journeyid" component={Journey} />
        <Route path="/receiver/:receiverid/:stockpilenr?" component={Receiver} />
        <Route path="/access-denied" component={NoAccess} />
    </div>

);

export default Homepage;