import { ActionTypes } from './_references';
import { logger } from './_references';

const dispatchConfig = ({ store, loggedInWithWrongUser, ...rest }) => {
    return new Promise((resolve) => {

        if (loggedInWithWrongUser) {
            logger.info('We have a logged in user, but he/she is not part of our organisation');
            store.dispatch({
                type: ActionTypes.LOGGED_IN_WRONG_USER
            });
        }

        resolve({
            store, loggedInWithWrongUser, ...rest
        });

    });
};

export default dispatchConfig;