import { STATE_KEY } from './constants';
import * as ActionTypes from './actionTypes';

import { setStateValue } from './_references';

const reducer = (state = {}, action) => {

    switch (action.type) {
    case ActionTypes.DATA_LOADED:
    {
        return setStateValue(action.key, action.data, state);
    }
    default:
        break;
    }

    return state;
};

reducer.STATE_KEY = STATE_KEY;

export default reducer;