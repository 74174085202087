import logger from 'redux-logger';
import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import logConfiguration from './logConfiguration';
import { logger as consoleLogger } from './_references';
import getPreservedState from './configureStore.getPreservedState';
import configureRootReducer from './configureRootReducer';
import rootSaga from './configureSaga';

const configureStore = ({ config, ...rest }) => {

    return new Promise((resolve) => {
        consoleLogger.info('Starting store configuration');
        const middleware = [];

        /* istanbul ignore if */
        if (process.env.NODE_ENV !== 'production'
        && (logConfiguration[ 'action' ] === undefined || logConfiguration[ 'action' ])) {
            middleware.push(logger);
        }
        const sagaMiddleware = createSagaMiddleware();

        middleware.push(sagaMiddleware);

        // next line is to support the redux devtools: https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?utm_source=chrome-app-launcher-info-dialog
        // when this isn't a development environment we disable the redux devtools
        const composeEnhancers = (process.env.NODE_ENV !== 'production' || config.exposeReduxStore ) &&
                typeof window === 'object' &&
                window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
            /* istanbul ignore next */
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

        const store = createStore(
            configureRootReducer(),
            getPreservedState(),
            composeEnhancers(
                applyMiddleware(...middleware)
            )
        );

        const sagaContext = {};

        sagaMiddleware.run(rootSaga, sagaContext);

        resolve({
            store,
            config,
            sagaContext,
            ...rest
        });

    });

};

export default configureStore;