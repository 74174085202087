import createLogger from '../util/createLogger';
import * as Actions from './actions';
import * as ActionTypes from './actionTypes';
import * as LoadingActions from '../universe/molecules/loading/actions';
import { getToken } from '../adal';

const logger = createLogger('Configuration');
const axiosLogger = createLogger('Axios');

export {
    Actions,
    ActionTypes,
    logger,
    axiosLogger,
    LoadingActions,
    getToken
};