import * as ActionTypes from './actionTypes';

export const ApiCallFailed = (error) => ({
    type: ActionTypes.API_CALL_FAILED,
    status: ((error || {}).response || {}).status || 'unknown response status',
    error,
    response: error.response
});

/**
 * Action that is dispached when the application is loaded so other data can be preloaded from the server
 * There is no guarantee that a user is logged in, so you should handle 401 and 403
 */
export const preLoad = (config) => ({
    type: ActionTypes.PRE_LOAD,
    config
});