import { all, call, takeEvery } from 'redux-saga/effects';

import { API_CALL_FAILED } from '../../configuration/actionTypes';

const apiFailureSaga = function* (context) {
    yield all([
        takeEvery( API_CALL_FAILED, (action) => handleFailedApi(action, context) ),
    ]);
};

const handleFailedApi = function* ( action, context ) {
    if (action.status === 401) {
        yield call (context.history.push, '/access-denied');
    }
    if (action.status === 403) {
        yield call (context.history.push, '/access-denied');
    }
};

export default apiFailureSaga;