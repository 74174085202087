export const NAME = 'NO_ACCESS';

export const STATE_KEY = `${NAME}/92516f24-e763-413f-be47-7f085e7154ad`;

export const Fields = {
    REASON: 'Reason'
};

export const Reasons = {
    NOT_ALLOWED: 'NotAllowed',
    NOT_PART_OF_ORGANISATION: 'NotPartOfOrganisation'
};